import axios from 'axios'
import Auth from '@/services/Auth'

const Http = axios.create({
    baseURL: process.env.VUE_APP_API_BASEURL
})

Http.interceptors.request.use((config) => {
    const access_token = localStorage.getItem('access_token')
    const csrf_token = localStorage.getItem('csrf_token')

    config.headers = {
        ...config.headers, 'SCAPI-KEY': `${process.env.VUE_APP_API_KEY}`,
    };
    config.headers = {
        ...config.headers, 'CSRF-TOKEN': `${csrf_token}`,
    };
    if(access_token){

        // Get the current timestamp
        const now = Math.floor(Date.now() / 1000);
        const publicPages = ['/auth/login', '/auth/validate', 'auth/refresh', '/auth/register', '/auth/forgot-password', '/auth/logout', '/auth/version','/auth/forgot_password', '/auth/change_forgot_password'];

        if(!publicPages.includes(config.url)){
            localStorage.setItem('last_action', now.toString());
        }

        config.headers = {
            ...config.headers, Authorization: `Bearer ${access_token}`,
        };
    }
    return config
});

let isRefreshing = false;
let refreshSubscribers = [];

function subscribeTokenRefresh(callback) {
    refreshSubscribers.push(callback);
}

function onRefreshed(newToken) {
    refreshSubscribers.forEach((callback) => callback(newToken));
    refreshSubscribers = [];
}


Http.interceptors.response.use(
    (res) => {

        const publicPages = ['auth/login', '/auth/validate', 'auth/refresh', 'auth/logout'];

        if(!publicPages.includes(res.config.url)){
            //console.log(res.config.url)
            const tokenExpiry = localStorage.getItem("expire");
            const lastAction = localStorage.getItem("last_action");
            const timeLeft = tokenExpiry - lastAction;
            //console.log(`Time left: ${timeLeft}`);
            if (timeLeft <= 600 && !isRefreshing) {

                isRefreshing = true;
                const refreshToken = localStorage.getItem("refresh_token");

                if (refreshToken) {
                    Auth.refresh({ refresh_token: refreshToken })
                        .then((response) => {
                            if (response.data && response.data.tokens) {
                                const newToken = response.data.tokens.access_token;
                                localStorage.setItem("access_token", newToken);
                                localStorage.setItem("expire", response.data.tokens.expire);
                                isRefreshing = false;
                                onRefreshed(newToken);
                            }else {
                                isRefreshing = false;
                            }
                        })
                        .catch(() => {
                            isRefreshing = false; // Reset the flag even if the refresh fails
                        });

                        // Return a promise to wait for the token refresh
                        return new Promise((resolve, reject) => {
                            subscribeTokenRefresh((newToken) => {
                                res.config.headers["Authorization"] = `Bearer ${newToken}`;
                                resolve(Http(res.config)); // Reattempt the request with the new token
                            });
                        });
                }else {
                    console.warn("No refresh token available.");
                    isRefreshing = false;
                }
            }
        }

        return res;
    },
    async (err) => {
        
        const error_code =  (typeof err.response.data.error_code != 'undefined') ? err.response.data.error_code : '';
        const originalConfig = err.config;
        if (
                originalConfig.url !== "/auth/login" 
            &&  originalConfig.url !== "/auth/validate"  
            && err.response
        ) {
            if (err.response.status === 401) {
                //check token
                Auth.validate()
                .then((response) => {

                })
                .catch((error) => {
                    localStorage.clear()
                    window.location.href = '/login'
                })
                
            }else if(error_code == '2FA_REQUIRED') {
                window.location.href = '/profile'
            }
        }  

        return Promise.reject(err);

    }

)

export default Http