import Vue from 'vue'
import Vuex from 'vuex'

import modules from './modules'

Vue.use(Vuex)

const store = new Vuex.Store({
  modules,
  // Enable strict mode in development to get a warning
  // when mutating state outside of a mutation.
  // https://vuex.vuejs.org/guide/strict.html
  strict: process.env.NODE_ENV !== 'production',

    state: {
        totalRows: 0,
    },
    mutations: {
        setTotalRows(state, total) {
            state.totalRows = total;
        },
        setBlockedUserRows(state, total) {
            state.setBlockedUserRows = total;
        },
    },

})

export default store

