
import Http from './Http'

export default {
    async login(credentials) {
        return await Http.post('auth/login', credentials)
    },
    async register(userData) {
        return await Http.post('/customers', userData)
    },
    async me() {
        return await Http.get('/user')
    },
    async validate() {
        const token = await Http.get('/auth/validate')
        const data = await token.data;
        return data;
    },
    async hasObjectPermission(data) {
        return await Http.patch('/auth/permission', data)
    },
    async auth2faStatus() {
        return await Http.get('/auth/2fa/status')
    },
    async generateQR() {
        return await Http.post('/auth/2fa/generateQR')
    },
    async confirm2FAOTP(data) {
        return await Http.post('/auth/2fa/confirmOTP', data)
    },
    async disable2FAOTP(data) {
        return await Http.post('/auth/2fa/disableOTP', data)
    },
    async confirm2FA(data) {
        return await Http.post('/auth/2fa/confirm', data)
    },
    async disable2FA(data) {
        return await Http.post('/auth/2fa/disable', data)
    },
    async changeAdminPassword(data) {
        return await Http.patch('/auth/admin/changePassword', data)
    },
    async getUsers(data) {
        return await Http.get(`/auth/users?${data}`)
    },
    async getRoles(data= '') {
        return await Http.get(`/auth/roles?${data}`)
    },
    async addUser(data){
        return await Http.post(`/auth/user`, data)
    },
    async verifyPhone(data){
        return await Http.post(`/auth/phone/verify`, data)
    },
    async confirmPhone(data){
        return await Http.patch(`/auth/phone/confirm`, data)
    },
    async removePhone(data){
        return await Http.delete(`/auth/phone`, data)
    },
    async editUser(data){
        return await Http.patch(`/auth/user`, data)
    },
    async deleteUser(user_id){
        return await Http.delete(`/auth/user/${user_id}`)
    },
    async changePassword(data){
        return await Http.patch(`/auth/user/changePassword`, data)
    },
    async getNavigationMenu(user_id) {
        return await Http.get(`auth/navigation/${user_id}`)
    },
    async get2faTypes(data) {
        return await Http.get(`/auth/2fa/types?`+data)
    },
    async edit2faTypes(data) {
        return await Http.patch(`/auth/2fa/types`, data)
    },
    async validate2FAOTP(data) {
        return await Http.post('/auth/2fa/validateOTP', data)
    },

    // RBAC
    async getRbacObjectRoleAccess(id=''){
        return await Http.get(`/auth/rbac_object_role_access?role_id=${id}`)
    },
    async getRbacRoles(){
        return await Http.get(`/auth/rbac_role`)
    },
    async updateRbacObjectRoleAccess(data) {
        return await Http.patch(`/auth/rbac_object_role_access`, data)
    },
    async getRbacRoleAccess(id=''){
        return await Http.get(`/auth/rbac_role_access?role_id=${id}`)
    },
    async updateRbacRoleAccess(data) {
        return await Http.patch(`/auth/rbac_role_access`, data)
    },
    async getApplicationMethodsExtended(id=''){
        return await Http.get(`/auth/application_methods_extended?app_id=${id}`)
    },
    async addApplicationMethod(data) {
        return await Http.post(`/auth/application_method`, data)
    },
    async getApplications() {
        return await Http.get(`/auth/application`)
    },
    async deleteApplicationMethod(data) {
        try {
            const response = await Http.delete(`/auth/application_method`, {
                params: data
            });
            return response;
        } catch (error) {
            console.error("Error in DELETE request:", error);
            throw error;
        }
    },
    async requestForgotPassword(data) {
        return await Http.post(`/auth/forgot_password`, data)
    },
    async changeForgotPassword(data){
        return await Http.patch(`/auth/change_forgot_password`, data)
    },
    async refresh(data) {
        return await Http.post('/auth/refresh', data)
    },
}